import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

function Payment() {
	// TODO, recibir los parámetros codificados para email, concepto, y no de orden, para con eso construir la pasarela de pago, y ver si podemos evitar que paguen más de 1 vez.
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const [paymentStatus, setPaymentStatus] = useState("");
	const [paymentPayer, setPaymentPayer] = useState("");
	const [decrptedEmail, setDecrptedEmail] = useState("");
	const [decrptedAmount, setDecrptedAmount] = useState("");
	const [decrptedToken, setDecrptedToken] = useState("");
	const [decrptedDoctor, setDecrptedDoctor] = useState("Pending...");
	const [decrptedDescription, setDecrptedDescription] = useState("");
	const { email, method, amount, description, token, doctor } = useParams();
	var pstatusTitle = 'Loading...';
	var pstatusBody = 'Please wait.';

	const secretPass = "XkhZG4fW2t2W";

	const getPaymentStatus = async (token) => {
		// let response = await fetch(`https://pro.vitagelab.com/v1/webhook-payment-status/${decrptedToken}`)
		if(token !== null && token !== undefined &&  token !== ''){
			let response = await fetch(`https://pro.vitagelab.com/v1/webhook-payment-status/${token}`)
			let responseJson = await response.json()
			let fromServer = responseJson.status
			let fromServerPayer = responseJson.payer
			setPaymentStatus(fromServer)
			console.log(fromServerPayer)
			setPaymentPayer(fromServerPayer)
		}
	}

	useEffect(() => {
		const substitutionsBeforeDecryption = new Map([
			["-", "+"],
			["_", "/"],
			["~", "="],
		]);
		const decryptData = (cipher, itemtype) => {
			if (cipher !== undefined && cipher !== null && cipher !== "") {
				const toDecrypt = cipher.replace(
					/[-_~]/g,
					(match) => substitutionsBeforeDecryption.get(match) ?? match
				);
				const bytes = CryptoJS.AES.decrypt(toDecrypt, secretPass);
				const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				if (itemtype === "amount_d") setDecrptedAmount(data);
				if (itemtype === "description_d") setDecrptedDescription(data);
				if (itemtype === "token_d") setDecrptedToken(data);
				if (itemtype === "email_d") setDecrptedEmail(data);
				if (itemtype === "doctor_d") setDecrptedDoctor(data);
			}
		};
		fetch("/config").then(async (r) => {
			const { publishableKey } = await r.json();
			setStripePromise(loadStripe(publishableKey));
		});
		if (method === "de") {
			decryptData(amount, "amount_d");
			decryptData(description, "description_d");
			decryptData(email, "email_d");
			decryptData(token, "token_d");
			decryptData(doctor, "doctor_d");
		}
		getPaymentStatus(decrptedToken);
	}, [amount, description, email, token, method, doctor, decrptedToken, paymentPayer]);

	useEffect(() => {
		const data = {
			amount: decrptedAmount * 100, // convert to cents
			description: decrptedDescription,
			email: decrptedEmail,
			token: decrptedToken,
			return_url: `${window.location.origin}/completion/${paymentPayer}`,
		};
		// return;
		if (
			data.amount !== null &&
			data.description !== null &&
			data.email !== null &&
			data.return_url !== null &&
			method === "de"
		) {
			fetch("/create-payment-intent", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}).then(async (result) => {
				var { clientSecret } = await result.json();
				setClientSecret(clientSecret);
			});
		}
		// 2500,"Laboratory tests","mmoreno@vitagelab.com"
	}, [
		decrptedAmount,
		decrptedDescription,
		decrptedEmail,
		decrptedToken,
		method
	]);

	const appearance = {
		theme: "stripe",
	};
	if(paymentStatus === 'paid') {
		pstatusTitle = `Order ${decrptedToken} already paid`;
		pstatusBody = 'This order has already been paid, please follow up within the VitageLab® Pro system for further updates.';
	}
	return (
		<>
			<img src="/static/logo/vitagelabpro.png" alt="" width={300} />

			{paymentStatus === 'unpaid' && (
				<>
					<h1>Pay for your laboratory tests</h1>
					<p>
						<strong style={{ color: "#57c0e8" }}>Requested by: </strong>{" "}
						{decrptedDoctor} <br />
						<strong style={{ color: "#57c0e8" }}>Order ID: </strong>{" "}
						{decrptedToken} <br />
						<strong style={{ color: "#57c0e8" }}>Amount: </strong> $
						{decrptedAmount} USD <br />
						<br />
					</p>
					{clientSecret && stripePromise && (
						<Elements
							stripe={stripePromise}
							options={{ clientSecret, appearance }}
						>
							<CheckoutForm payer={paymentPayer} />
						</Elements>
					)}
					<small>
						Secure Payments provided by{" "}
						<span style={{ color: "#57c0e8" }}>Stripe®.</span>
					</small>
					<br />
					<small>
						This page is only intended to receive your payment. For more
						details about what is included in this order, please review the
						email sent previously. To review our Privacy Policy, please
						enter vitagelab.com
					</small>
				</>
			)}

			{(paymentStatus !== 'unpaid') && (
				<>
					<h1 id="PaymentStatus">{pstatusTitle}</h1>
					<p id="PaymentDetails">
						{pstatusBody}
					</p>
				</>
			)}
		</>
	);
}

export default Payment;
